<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Cabezera de la pagina principal -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tipos de Bonos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Bonos</li>
                  <li class="breadcrumb-item active">Tipos de Bono</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <!-- Cuerpo de la pagina -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- Cabecera de la tarjeta -->
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <!-- Div que empuja el boton a la izquierda -->
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        data-toggle="modal"
                        data-target="#Modal_Tipo_Bonos"
                        v-if="
                          $store.getters.can('hidrocarburos.tiposBonos.create')
                        "
                        @click="abrirModal('Agregar', null)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.cabezera de la tarjeta -->
              <div class="card-body p-0">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Tipo Ruta
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_ruta"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tip_ruta in listasForms.tipo_rutas"
                            :key="tip_ruta.numeracion"
                            :value="tip_ruta.numeracion"
                          >
                            {{ tip_ruta.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Valor
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.valor"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Temporada
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.temporada"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="temporada in listasForms.temporada"
                            :key="temporada.numeracion"
                            :value="temporada.numeracion"
                          >
                            {{ temporada.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th style="text-align-last: center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tipos_bonos.data" :key="item.id">
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="item.tipo_ruta == 1 ? 'bg-navy' : 'bg-info'"
                        >
                          {{ item.nTipoRuta }}
                        </span>
                      </td>
                      <td>{{ item.nombre }}</td>
                      <td class="text-center">{{ item.fecha_ini }}</td>
                      <td class="text-center">{{ item.fecha_fin }}</td>
                      <td class="text-right">{{ item.valor }}</td>
                      <td>{{ item.nTemporada }}</td>
                      <td class="text-right" style="width: 25px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#Modal_Tipo_Bonos"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.tiposBonos.edit'
                              )
                            "
                            @click="abrirModal('Editar', item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(item.id)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.tiposBonos.delete'
                              )
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="tipos_bonos.total">
                  <p>
                    Mostrando del <b>{{ tipos_bonos.from }}</b> al
                    <b>{{ tipos_bonos.to }}</b> de un total:
                    <b>{{ tipos_bonos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="tipos_bonos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- Modal de agregar y editar -->
      <div class="modal fade" id="Modal_Tipo_Bonos">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">{{ modal.title }}</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body">
              <div class="row">
                <div class="form-group col-md-4">
                  <label>Tipo de Ruta</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.tipo_ruta"
                    :class="
                      $v.form.tipo_ruta.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tip_ruta in listasForms.tipo_rutas"
                      :key="tip_ruta.numeracion"
                      :value="tip_ruta.numeracion"
                    >
                      {{ tip_ruta.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-8">
                  <label>Nombre</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.nombre"
                    :class="$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Fecha Inicial</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="form.fecha_ini"
                    :class="
                      $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas()"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Fecha Final</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="form.fecha_fin"
                    :class="
                      $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas()"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Valor</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model="form.valor"
                    :class="$v.form.valor.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Temporada</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.temporada"
                    :class="
                      $v.form.temporada.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="temporada in listasForms.temporada"
                      :key="temporada.numeracion"
                      :value="temporada.numeracion"
                    >
                      {{ temporada.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-show="!$v.form.$invalid"
                @click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TipoBonoIndex",
  components: {
    Loading,
    pagination,
  },
  data() {
    return {
      cargando: false,
      tipos_bonos: {},
      modal: {
        title: "",
        accion: "",
      },
      filtros: {
        tipo_ruta: null,
        nombre: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
      },
      listasForms: {
        tipo_rutas: [],
        temporada: [],
      },
      form: {
        id: null,
        tipo_ruta: null,
        nombre: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
        temporada: null,
      },
    };
  },
  validations: {
    form: {
      tipo_ruta: {
        required,
      },
      nombre: {
        required,
      },
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      valor: {
        required,
      },
      temporada: { required },
    },
  },
  methods: {
    // Métodos iniciales
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/tiposBonos?page=" + page, {
          params: this.filtros /* parametros que se pasan en la ruta */,
        })
        .then((response) => {
          this.cargando = false;
          this.tipos_bonos = response.data;
        });
    },

    getTipoRuta() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },

    getTemporada() {
      axios.get("/api/lista/121").then((response) => {
        this.listasForms.temporada = response.data;
      });
    },

    // metodo de apertura del modal
    abrirModal(accion, data = null) {
      this.modal.accion = accion;
      this.modal.title = accion + " Tipo de Bono";
      this.resetForm();
      if (data) {
        this.form = {
          id: data.id,
          tipo_ruta: data.tipo_ruta,
          nombre: data.nombre,
          fecha_ini: data.fecha_ini,
          fecha_fin: data.fecha_fin,
          valor: data.valor,
          temporada: data.temporada,
        };
      }
    },

    // Métodos para validación de los formularios
    resetForm() {
      this.form = {
        id: null,
        tipo_ruta: null,
        nombre: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
      };
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    // Métodos de acción de los formularios
    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios
          .post("/api/hidrocarburos/tiposBonos", this.form)
          .then((response) => {
            this.cargando = false;
            this.$refs.closeModal.click();
            this.resetForm();
            this.getIndex(); //Lista de nuevo
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(id) {
      this.$swal({
        title: "Está seguro de eliminar este Tipo de Bono?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios.delete("/api/hidrocarburos/tiposBonos/" + id).then(() => {
            this.cargando = false;
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se eliminó el Tipo de Bono exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },

  mounted() {
    this.getIndex();
    this.getTipoRuta();
    this.getTemporada();
  },
};
</script>
